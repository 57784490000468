html {
  box-sizing: border-box;
  height: 100%;
} 

*, *:before, *:after {
  box-sizing: inherit;
  -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

*:focus{
  outline: none
}

body{
  position: relative;
  min-height:100%;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  line-height: 1.5;
}

a[href]:empty::before {
  content: attr(href);
}

main{
  -webkit-box-flex:1;
      -ms-flex:1 0 auto;
          flex:1 0 auto;
}

img{
  max-width:100%;
  height:auto;
  vertical-align:middle;
}

button{
  border:0;
  -webkit-appearance:none;
}