body{
	margin:0;
	font-family:"proxima-nova",sans-serif;
	font-style:normal;
	font-size:14px;
	color:#555;
}

main{
	background-color:#fafafa;
	background-image:url(../images/texture-bg.png);
	background-blend-mode:multiply;
}

.services-page{
	background-color:#fff;
	background-image:none;
	background-blend-mode:normal;
}

.karmina{
	font-family:"karmina",serif;
}

a{
	color:#0099DC;
	text-decoration:none;
	transition:all 0.2s;
}

a:hover,
a:focus,
a:active{
	color:#0099DC;
}

.headline-small{
	font-size:13px;
	font-weight:800;
	letter-spacing:0.57px;
	line-height:15px;
	text-transform:uppercase;
}

.related h3{
	font-size:30px;
}

hr{
	width:35px;
	height:2px;
	margin:0 auto 24px;
	border:none;
}

.btn{
	font-weight:700;
}

.page-content{
	min-height:600px;
	padding-top:40px;
	padding-bottom:40px;
}

.page-content ~ .hook{
	margin-top:400px;
}

.page-header{
	padding-top:16px;
	padding-bottom:16px;
	background-color:#0099DC
}

.page-header--no{
	padding:0;
}

.page-header h1{
	color:#fff;
	text-shadow:0 1px 1px rgba(0, 0, 0, .20);
}

h1, h2, h3, h4, h5, h6,
p, ul, ol, address{
  margin:0 0 20px;
}

blockquote{
	position:relative;
  margin:0;
  padding-left:16px
}

blockquote p{
	font-size:24px;
	line-height:1;
}

blockquote p:before{
	position:absolute;
	top:0;
	left:0;
	content:open-quote;
}

blockquote p:after{
	margin-left:8px;
	content:close-quote;
}

blockquote cite{
	display:block;
	width:100%;
	padding-top:16px;
	font-size:16px;
	text-align:right;
}

blockquote cite:before{
	content:'- ';
}

.bg-default{
	background-color:#fafafa;
	background-image:url(../images/texture-bg.png);
	background-blend-mode:multiply;
}

.bg-white{
	background-color:#fff;
}

.bg-red{
	color:#fff;
	text-shadow:0 1px 1px rgba(0, 0, 0, .20);
	background-color:#DA513D;
}

.bg-lightBlue{
	color:#fff;
	text-shadow:0 1px 1px rgba(0, 0, 0, .20);
	background-color:#0099DC;
}

.bg-darkBlue{
	color:#fff;
	text-shadow:0 1px 1px rgba(0, 0, 0, .20);
	background-color:#293E50;
}

.bg-darkGrey{
	color:#fff;
	text-shadow:0 1px 1px rgba(0, 0, 0, .20);
	background-color:#4A4A4A;
}

address{
  font-style:normal;
}

figure{
  margin:0;
  margin-bottom:20px;
}

figcaption{
  font-style:italic;
}

.keepswimming{
  display:block;
  left:-9999px;
  position:absolute;
  height:0;
  margin-bottom:0;
}

pre{
	padding:20px;
	background-color:#efefef;
	white-space:pre-wrap;
}

.container{
  position:relative;
  width:100%;
  margin:0 auto;
  padding-left:20px;
  padding-right:20px;
}

.container:after, 
.clear:after,
.column-wrap:after,
.field-group:after{
  content:"";
  display:table;
  clear:both;
}

.container{
	max-width:1180px;
}

.container--1420{
	max-width:1480px;
}

.container--980{
	max-width:980px;
}

.container--800{
	max-width:800px;
}

.container--680{
	max-width:680px;
}

.container--400{
	max-width:400px;
}

.container{
}

.container .column{
	position:relative;
	float:left;
	width:100%;
	margin-right:20px;
	margin-bottom:10px;
	vertical-align:top;
}

.container--flex{
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
}

.left{
  float:left;
}

.right{
  float:right;
}

.text-center{
  text-align:center;
}

.text-right{
  text-align:right;
}

nav ul, nav ol,
.no-bull, .inline-list{
  list-style:none;
  margin:0;
  padding:0;
}

nav li,
.no-bull li, .inline-list li{
  margin:0;
}

.inline-list{
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
}

.inline-list--social{
}

.inline-list--social li{
	margin-right:15px;
}

.inline-list--social li:last-of-type{
	margin-right:0;
}

.inline-list--social a{
	display:block;
}

.inline-list--social a svg{
	display:block;
	width:100%;
	height:100%;
}

.inline-list--social a svg path{
	fill:#0099DC;
	transition:0.3s;
}

.inline-list--social a:hover svg path,
.inline-list--social a:focus svg path,
.inline-list--social a:active svg path{
	fill:#293E50;
}

.inline-list--bullet{
}

.inline-list--bullet li:not(:last-of-type)::after{
	content:'\2022';
	margin-left:8px;
	margin-right:8px;
}

.giga{ 
  font-size:40px
}

.mega{ 
  font-size:36px
}

.kilo{ 
  font-size:32px
}

h1, .alpha{ 
  font-size:48px
}

h2, .beta{ 
  font-size:40px
}

h3, .gamma{ 
  font-size:32px
}

h4, .delta{ 
  font-size:24px
}

h5, .epsilon{ 
  font-size:16px
}

h6, .zeta{
  font-size:14px
}

small, .milli{
  font-size:10px;
}

.truncate{
  text-overflow:ellipsis;
  overflow:hidden;
  white-space:nowrap;
}

.lead{
  font-size:18px;
}

.vid-wrap{
  position:relative;
  float:left;
  width:100%;
  height:0;
  margin-bottom:24px;
  padding-bottom:56.25%;
}

.vid-wrap iframe, 
.vid-wrap object, 
.vid-wrap embed{
  position:absolute;
  top:0;left:0;
  width:100%;
  height:100%;
}

.text-hide{
  text-indent:100%;
  white-space:nowrap;
  overflow:hidden;
}

.is-hidden{
	visibility:hidden;
	opacity:0;
}

.is-visible{
	visibility:visible;
	opacity:1;
}

.menu{
	position:relative;
	display:block;
	width:100%;
	height:1px;
	margin-bottom:0;
	padding:0;
	font-size:0;
	background-color:#fff;
	transition:-webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition:transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition:transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)
}

.menu:before,
	.menu:after{
	content:'';
	position:absolute;
	top:0;
	right:0;
	display:block;
	width:100%;
	height:1px;
	background-color:#fff;
}

.menu:before{
	top:-7px;
}

.menu:after{
	top:7px;
}

.menu.is-active{
	-webkit-transform:rotate(45deg);
	        transform:rotate(45deg)
}

.menu.is-active:before{
	top:0;
	opacity:0;
}

.menu.is-active:after{
	top:0;
	-webkit-transform:rotate(-90deg);
	        transform:rotate(-90deg);
}

.pagination{
  padding-top:32px;
  text-align:center;
}

.pagination__nav{
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
}

.pagination__nav a,
.pagination__nav span{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  width:32px;
  height:32px;
  margin-right:4px;
  margin-left:4px;
  font-weight:700;
  font-size:18px;
  color:#DA513D;
  text-decoration:none;
}

.pagination__nav a:not(.is-last):not(.is-first),
.pagination__nav .is-current{
  padding:0 8px;
  border-radius:50%;
}

.pagination__nav .is-current{
  background-color:#DA513D;
  color:#fff;
  text-shadow:0 1px 1px rgba(0, 0, 0, .20);
}

.pagination__nav .is-first{
  margin-right:16px;
}

.pagination__nav .is-last{
  margin-left:16px;
}

.pagination__nav a:not(.is-last):not(.is-first):hover{
  background-color:#dfdfdf;
}

.sharebar{
	position:fixed;
	top:0;
	z-index:500;
	width:100%;
	color:#fff;
	transition:top 0.2s ease-in-out;
}

.sharebar.hideshare{
	top:-40px;
	transition:top 0.2s ease-in-out;
}

.sharebar .container{
	height:40px;
	padding:0 20px;
	background-color:#0099DC;
	font-size:0;
	box-shadow:0px 2px 5px 0px rgba(0, 0, 0, .1);
}

.sharebar p{
	font-size:14px;
	line-height:40px;
	text-align:right;
}

.btn{
	display:block;
	width:100%;
	max-width:200px;
	margin:0 auto;
	padding-top:16px;
	padding-bottom:16px;
	font-size:14px;
	font-weight:800;
	letter-spacing:1.4px;
	line-height:15px;
	text-align:center;
	background-color:transparent;
	border:2px solid;
	border-radius:100px;
	cursor:pointer;
	transition:all 0.2s
}

.btn--blue{
	color:#0099DC;
	border-color:#0099DC;
}

.btn--blue:hover,
.btn--blue:focus,
.btn--blue:active{
	color:#fff;
	background-color:#0099DC;
}

.btn--white, .btn--ghost{
	color:#fff;
	border-color:#fff;
}

.btn--white:hover,
.btn--white:focus,
.btn--white:active,
.btn--ghost:hover,
.btn--ghost:focus,
.btn--ghost:active{
	color:#0099DC;
	background-color:#fff;
}

.btn--dkblue{
	color:#293E50;
	border-color:#293E50;
}

.btn--dkblue:hover,
.btn--dkblue:focus,
.btn--dkblue:active{
	color:#fff;
	background-color:#293E50;
}

.btn--red{
	color:#fff;
	text-shadow:0 1px 1px rgba(0, 0, 0, .20);
	background-color:#DA513D;
	border:none;
}

.btn--red:hover,
.btn--red:focus,
.btn--red:active{
	color:#fff;
	background-color:rgb(196, 73, 55);
}

.btn--site{
	margin:0;
	margin-top:25px;
}

input::-webkit-input-placeholder, 
textarea::-webkit-input-placeholder{
  color:#dedede;
  transition:color 0.15s linear;
}

input::-moz-placeholder, 
textarea::-moz-placeholder{
  color:#dedede;
  transition:color 0.15s linear;
}

input:-moz-placeholder, 
textarea:-moz-placeholder{
  color:#dedede;
  transition:color 0.15s linear;
}

input:-ms-input-placeholder, 
textarea:-ms-input-placeholder{
  color:#dedede;
  transition:color 0.15s linear;  
}

input:focus::-webkit-input-placeholder, 
textarea:focus::-webkit-input-placeholder{
  color:#ededec;
}

input:focus::-moz-placeholder, 
textarea:focus::-moz-placeholder{
  color:#ededec;
}

input:focus:-moz-placeholder, 
textarea:focus:-moz-placeholder{
  color:#ededec;
}

input:focus:-ms-input-placeholder, 
textarea:focus:-ms-input-placeholder{  
  color:#ededec;  
}

form{
	margin-bottom:48px
	
}

form.form-flex{
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
}

form.form-flex .field--append{
	-webkit-box-flex:0;
	    -ms-flex:0 0 70%;
	        flex:0 0 70%;
	margin-bottom:0;
}

form.form-flex .field--append input{
	border-right:0;
	border-top-right-radius:0;
	border-bottom-right-radius:0;
}

form.form-flex .field--append ~ button{
	-webkit-box-flex:1;
	    -ms-flex:1;
	        flex:1;
	border-top-left-radius:0;
	border-bottom-left-radius:0;
}

form .field{
	position:relative;
	margin-bottom:16px;
}

form label{
	display:block;
	margin-bottom:8px;
}

form .field-group > .field--half{
	float:left;
	width:calc(99.9% * 1/2 - 10px);
	margin-right:20px;
}

form .field-group > .field--half:nth-child(2n){
	margin-right:0;
}

input,
textarea{
	width:100%;
	margin:0;
	padding:16px;
	font-weight:800;
	line-height:1.25;
	color:#fff;
	background:#fff;
	border:1px solid #ddd;
	border-radius:4px;
	box-shadow:0 0 0 0 transparent inset;
	-webkit-appearance:none;
	tap-highlight-color:#fff;
	transition:color .1s ease, border-color .1s ease
}

input.color, textarea.color{
	background-color:#ddd;
}

input.ghost, textarea.ghost{
	padding-left:0;
	padding-right:0;
	background-color:transparent;
	border:none;
	border-bottom:2px solid #fff;
	border-radius:0;
	transition:0.3s;
}

input.ghost::-webkit-input-placeholder, textarea.ghost::-webkit-input-placeholder{
	color:#fff;
	font-weight:800;
	transition:0.3s;
}

input.ghost:-ms-input-placeholder, textarea.ghost:-ms-input-placeholder{
	color:#fff;
	font-weight:800;
	transition:0.3s;
}

input.ghost::placeholder, textarea.ghost::placeholder{
	color:#fff;
	font-weight:800;
	transition:0.3s;
}

input.ghost:focus::-webkit-input-placeholder, textarea.ghost:focus::-webkit-input-placeholder{
	opacity:0.5;
}

input.ghost:focus:-ms-input-placeholder, textarea.ghost:focus:-ms-input-placeholder{
	opacity:0.5;
}

input.ghost:focus::placeholder, textarea.ghost:focus::placeholder{
	opacity:0.5;
}

input:placeholder{
	color:#fff;
}

.prepend-icon{
	width:16px;
  height:16px;
  font-size:16px;
  line-height:1;
  position:absolute;
  top:50%;
  margin-top:-10px;
  left:16px;
  z-index:2;
}

.prepend-icon ~ input{
	padding-left:40px;
}

input ~ .append-icon{
	padding-right:32px;
}

.primary-nav{
	padding:32px 20px
}

.primary-nav .container{
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:justify;
	    -ms-flex-pack:justify;
	        justify-content:space-between;
	width:100%;
}

.primary-nav__list{
	-webkit-box-flex:1;
	    -ms-flex:1;
	        flex:1
}

.primary-nav__list li{
	font-size:14px;
	font-weight:700;
	text-transform:uppercase;
	letter-spacing:1.4px;
}

.primary-nav__list li a{
	color:#fff;
	text-decoration:none;
}

.primary-nav__list li a:hover,
.primary-nav__list li a:focus,
.primary-nav__list li a:active{
	color:#fff;
}

.mobile-nav{
	position:fixed;
	bottom:0;
	z-index:100;
	width:100%;
	background-color:#0099DC;
}

.mobile-nav__list{
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex
}

.mobile-nav__list li{
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	width:20%;
	font-size:16px;
	border-top:1px solid rgba(255, 255, 255, 0.2);
}

.mobile-nav__list li a{
	display:block;
	padding-top:6px;
	padding-bottom:6px;
	color:#fff;
	text-align:center;
	transition:all 0.6s ease-in-out;
}

.mobile-nav__list li a img{
	display:inline-block;
	height:16px;
	margin-bottom:4px;
}

.mobile-nav__list li a span{
	display:block;
	font-size:10px;
	font-weight:700;
	letter-spacing:0.75px;
	text-transform:uppercase;
}

.mobile-nav__list li:not(:last-of-type){
	border-right:1px solid rgba(255, 255, 255, 0.2);
}

.mobile-nav__list li.is-active{
	background-color:rgb(0, 130, 187);
}

.mobile-nav__list.is-active{
}

.mobile-nav__list.is-active li:not(:last-of-type){
	opacity:0.5;
}

.mobile-nav__toggle{
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	width:25px;
	height:25px;
}

.header{
	background:url(../images/hero-bg.jpg) no-repeat;
	background-size:cover
}

.header .bg-cover{
	display:block;
	width:100%;
	height:100%;
	background-color:rgba(41, 62, 80, .85);
}

.hero{
	max-width:800px;
	margin:50px auto 100px;
	padding-left:20px;
	padding-right:20px;
	font-family:"proxima-nova-condensed",sans-serif;
	color:#fff;
	text-align:center
}

.hero h1{
	margin-bottom:16px;
	font-size:48px;
	font-weight:400;
	letter-spacing:1px;
	line-height:50px;
	text-transform:uppercase;
}

.hero p{
	max-width:475px;
	margin:0 auto;
	font-size:20px;
	line-height:24px;
	letter-spacing:0.02px;
}

.hero p.text-large{
	font-size:30px;
}

.hero .inline-list{
	-webkit-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
}

.hero .inline-list li:not(:last-of-type){
	margin-right:12px;
}

.hero--blog{
	margin-bottom: 150px;
	padding-bottom: 80px;
}

.page-header{
	padding:50px 20px;
	padding-bottom:100px;
	color:#fff;
	text-align:center;
	background-color:#0099DC;
	text-shadow:0 1px 1px rgba(0, 0, 0, .20)
}

.page-header h2, .page-header h3{
	margin-bottom:24px;
	font-family:"karmina",serif;
	font-size:32px;
	font-weight:700;
	line-height:1;
	letter-spacing:2px;
}

.page-header p{
	margin:0;
}

.latest{
	margin-top:24px;
	z-index:20
}

.latest .btn{
	clear:both;
}

.latest + .content{
	position:relative;
}

.services{
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-flex:1;
	    -ms-flex:1;
	        flex:1;
	-ms-flex-wrap:wrap;
	    flex-wrap:wrap;
	margin-bottom:32px;
}

.panel-wrap{
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	    flex-wrap:wrap;
	width:100%;
	color:#fff;
	box-shadow:0 0 10px 0 rgba(0, 0, 0, .30)
}

.panel-wrap .panel{
	position:relative;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	width:100%;
	height:292px;
	background-color:#fff;
}

.panel-wrap .panel-content{
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-flex:1;
	    -ms-flex:1 1 auto;
	        flex:1 1 auto;
	-webkit-box-ordinal-group:2;
	    -ms-flex-order:1;
	        order:1;
	-webkit-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	width:100%;
	color:#fff;
	background-color:#DA513D;
}

.panel-wrap .panel-content h2{
	font-family:"karmina",serif;
	font-size:36px;
	font-weight:800;
	line-height:1;
	text-align:center;
}

.panel-wrap .panel-content hr{
	background-color:#fff;
}

.panel-wrap .panel-content .btn:hover,
.panel-wrap .panel-content .btn:focus,
.panel-wrap .panel-content .btn:active{
	color:#DA513D;
}

.panel-wrap .panel-img--fit img{
	width:100%;
	height:292px;
	-o-object-fit:cover;
	   object-fit:cover;
}

.panel-wrap .panel-img--fit img.top-img{
	height:auto;
	-o-object-fit:none;
	   object-fit:none;
}

.panel-wrap .panel-img.panel--tall img{
	height:320px;
	margin-top:40px;
}

.panel-wrap:nth-of-type(3),
.panel-wrap:nth-of-type(4){
	width:100%
}

.panel-wrap:nth-of-type(3) .panel, .panel-wrap:nth-of-type(4) .panel{
	width:100%;
}

.panel-wrap:nth-of-type(3) .panel--tall, .panel-wrap:nth-of-type(4) .panel--tall{
	height:350px;
}

.top-img{
	position:absolute;
	top:50%; left:50%;
	-webkit-transform:translate(-50%, -50%);
	        transform:translate(-50%, -50%);
	z-index:10;
}

.bottom-img{
	height:292px;
	-o-object-fit:cover;
	   object-fit:cover;
}

.card{
	position:relative;
	z-index:20;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	width:100%;
	margin-bottom:32px;
	padding:54px 40px;
	text-align:center;
	background-color:#fff;
	box-shadow:0 0 10px 0 rgba(0, 0, 0, .30);
	border-radius:8px
}

.card h2{
	margin-bottom:20px;
	font-size:30px;
	font-weight:800;
	color:#0099DC;
	line-height:24px;
	letter-spacing:0.82px;
	text-transform:uppercase;
	word-spacing:500px;
}

.card hr{
	background-color:#9B9B9B;
}

.card h3{
	margin-bottom:20px;
}

.card p{
	-webkit-box-flex:1;
	    -ms-flex:1;
	        flex:1;
}

.card p:last-of-type{
	margin-bottom:0;
}

.card .inline-list{
	-webkit-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	margin-top:48px;
	margin-bottom:48px;
}

.card.card--solo{
	height: 100%;
}

.card--solo address{
	margin-bottom: 0;
}

.hook{
	position:relative;
	min-height:338px;
	background-color:#293E50;
	text-align:center
}

.hook__content{
	position:absolute;
	top:35%;
	left:0;
	right:0;
	margin-left:auto;
	margin-right:auto;
}

.hook h2{
	margin-bottom:40px;
	font-family:"karmina",serif;
	font-size:32px;
	font-weight:700;
	color:#fff;
	line-height:1;
	letter-spacing:2px;
	text-shadow:0 1px 1px rgba(0, 0, 0, .20);
}

.hook .btn{
	position:relative;
	z-index:100;
}

.hook .btn:hover{
	color:var(--dkblue);
}

.reggie{
	display:block;
	z-index:25;
	width:85%;
	max-width:800px;
	max-height:618px;
	margin-left:auto;
	margin-right:auto;
	-webkit-transform:translate(-30px, -45%);
	        transform:translate(-30px, -45%);
	-webkit-animation-name:bob;
	        animation-name:bob;
	-webkit-animation-duration:4.2s;
	        animation-duration:4.2s;
	-webkit-animation-iteration-count:10;
	        animation-iteration-count:10;
	-webkit-animation-direction:alternate;
	        animation-direction:alternate;
	-webkit-animation-timing-function:ease-out;
	        animation-timing-function:ease-out;
	-webkit-animation-fill-mode:forwards;
	        animation-fill-mode:forwards;
	-webkit-animation-delay:0s;
	        animation-delay:0s;
}

@-webkit-keyframes bob{
	0%{ -webkit-transform:translate(-30px, -45%); transform:translate(-30px, -45%); }
	50%{ -webkit-transform:translate(-30px, -48%); transform:translate(-30px, -48%); }
	100%{ -webkit-transform:translate(-30px, -45%); transform:translate(-30px, -45%); }
}

@keyframes bob{
	0%{ -webkit-transform:translate(-30px, -45%); transform:translate(-30px, -45%); }
	50%{ -webkit-transform:translate(-30px, -48%); transform:translate(-30px, -48%); }
	100%{ -webkit-transform:translate(-30px, -45%); transform:translate(-30px, -45%); }
}

.subscribe{
	padding-top:48px;
	padding-bottom:48px;
	color:#fff;
	text-shadow:0 1px 1px rgba(0, 0, 0, .20);
	background-color:#DA513D
}

.subscribe .container{
	padding-left:20px;
	padding-right:20px;
}

.subscribe h2{
	margin-bottom:20px;
	font-family:"karmina",serif;
	font-size:41px;
	font-weight:700;
	text-align:center;
}

.subscribe form{
	position:relative;
	margin-bottom:0;
}

.subscribe form label{
	display:none;
}

.subscribe form input{
	margin-bottom:20px;
	background-color:transparent;
	border:none;
	border-bottom:2px solid #fff;
	border-radius:0;
}

.subscribe form input:focus{
	padding:16px;
	background-color:rgb(214, 79, 60);
	border-bottom-color:transparent;
}

.subscribe form input:focus::-webkit-input-placeholder{
	color:rgb(214, 79, 60);
	text-shadow:none;
}

.subscribe form input:focus:-ms-input-placeholder{
	color:rgb(214, 79, 60);
	text-shadow:none;
}

.subscribe form input:focus::placeholder{
	color:rgb(214, 79, 60);
	text-shadow:none;
}

.subscribe form .btn:hover,
.subscribe form .btn:focus,
.subscribe form .btn:active{
	color:#DA513D;
}

.footer{
	padding-top:64px;
	padding-bottom:64px;
	color:#293E50;
	font-weight:700;
	letter-spacing:3px;
	text-transform:uppercase;
	background-color:#fff
}

.footer .container{
	width:100%;
}

.footer .column:last-of-type{
	margin-right:0;
}

.footer a{
	font-weight:700;
	color:currentColor;
}

.footer a:hover,
.footer a:focus,
.footer a:active{
	color:#DA513D;
}

.footer h3{
	font-size:40px;
}

.footer__about{
	margin-bottom:48px;
	padding-bottom:48px;
	font-size:13px;
	border-bottom:2px solid currentColor;
}

.footer__about p{
	margin-bottom:0;
}

.footer__copyright{
	font-size:0;
}

.footer__copyright p{
	display:block;
	font-size:14px;
}

.footer__copyright nav{
	display:none;
}

.footer__social-list{
	margin-top:16px
}

.footer__social-list .inline-list--social{
}

.footer__social-list .inline-list--social a svg path{
	fill:#293E50;
}

.footer__social-list .inline-list--social a:hover svg path,
.footer__social-list .inline-list--social a:focus svg path,
.footer__social-list .inline-list--social a:active svg path{
	fill:#0099DC;
}

.overlay{
	width:100%;
	height:100%;
}

.focus{
	position:relative
}

.focus .container--flex{
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	-webkit-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
}

.focus .card:first-of-type{
	margin-top:-50px;
}

.about{
	width:100%;
  overflow:hidden
	
	
}
@media only screen and (max-width:768px){
	.about--bluefish{
		background:#0099DC !important;
	}
}

.about--madness{
	background-color:#293E50;
}

.about .container{
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	-webkit-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	height:100%;
	padding:0;
}

.about__content{
	width:100%;
	padding:40px;
	color:#fff;
	text-align:center;
}

.about__content h2{
	margin-bottom:20px;
	font-size:42px;
	font-weight:800;
	line-height:1;
	text-transform:uppercase;
}

.about__content hr{
	background-color:#fff;
}

.about__content h3{
	margin-bottom:20px;
}

.about--bluefish .about__content{
	background-color:rgba(0, 153, 220, 0.75);
}

.about--madness .about__content{
	background-color:rgba(41, 62, 80, 0.75);
}

.about--madness .about__content .btn:hover,
.about--madness .about__content .btn:focus,
.about--madness .about__content .btn:active{
	color:#293E50;
}

.info{
}

.info .container{
	padding:0;
}

.blogroll{
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	    flex-wrap:wrap;
	z-index:10;
	width:100%;
	-ms-flex-item-align:start;
	    align-self:flex-start;
	background-color:#fff;
	box-shadow:0 0 10px 0 rgba(0, 0, 0, .30)
}

.blogroll h2{
	width:100%;
	font-size:20px;
	color:#E3E2DA;
	line-height:67px;
	text-align:center;
	text-transform:uppercase;
	background-color:#293E50;
}

.blogroll .btn{
	margin-bottom:32px;
}

.blog-snippet{
	padding:32px
}

.blog-snippet:last-of-type{
	margin-bottom:32px;
}

.blog-snippet a{
	color:#DA513D;
}

.blog-snippet .meta{
	margin-bottom:8px;
	font-size:12px;
	font-weight:700;
	color:#293E50;
	text-transform:uppercase;
}

.blog-snippet .meta .category:before{
	content:" • ";
	color:#293E50;
}

.blog-snippet h3{
	margin-bottom:12px;
	font-size:13px;
	color:#293E50;
	text-transform:uppercase;
}

.blog-snippet h3 a{
	color:#293E50;
}

.blog-snippet p{
	font-family:"proxima-nova-condensed",sans-serif;
	font-weight:600;
	color:#7A7A7A;
}

.blog-snippet .more{
	display:block;
	max-width:75px;
	padding:6px 12px;
	font-weight:700;
	text-align:center;
	text-transform:uppercase;
	border:2px solid #DA513D;
	border-radius:100px;
}

.blog-snippet .more:hover,
.blog-snippet .more:focus,
.blog-snippet .more:active{
	color:#fff;
	background-color:#DA513D;
}

.page-list__title{
	padding-top:50px;
	padding-bottom:50px;
	color:#0099DC;
	text-align:center
}

.page-list__title h2{
	font-size:36px;
	font-weight:700;
}

.page-list{
	margin-bottom:100px
}

.page-list__item{
	padding-top:40px;
}

.page-list__item:nth-of-type(even){
	background-color:#fafafa;
	background-image:url(../images/texture-bg.png);
	background-blend-mode:multiply;
}

.page-list__item{
	overflow:hidden;
	padding-top:40px;
	padding-bottom:40px
}

.page-list__item.zero-bottom{
	padding-bottom:0;
}

.page-list__item:last-of-type{
	margin-bottom:500px;
}

.page-list__item .column{
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	-ms-flex-line-pack:center;
	    align-content:center;
	-webkit-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	margin-bottom:0;
}

.page-list__item h2{
	font-size:32px;
	font-weight:800;
	line-height:1;
	text-transform:uppercase;
}

.page-list__item .btn{
	margin-left:0;
	margin-bottom:40px;
}

.page-block{
	overflow:hidden;
	padding-top:40px;
	padding-bottom:40px
}

.page-block.zero-bottom{
	padding-bottom:0;
}

.page-block h2{
	margin-bottom:20px;
	font-size:24px;
	font-weight:800;
	line-height:1;
	letter-spacing:0.82px;
	text-transform:uppercase;
}

.page-block h3{
	font-size:18px;
}

.page-block p:last-of-type{
	margin-bottom:0;
}

.page-block .vid-wrap{
	margin-bottom:0;
}

.content-block{
	max-width:450px
}

.content-block--right{
	float:right;
}

.img-inline{
	margin-bottom:0;
	text-align:center;
}

.magnet__text{
	margin-bottom:48px;
}

.magnet__form{
}

.magnet__form form{
	position:relative;
	margin-bottom:0;
}

.magnet__form form label{
	display:none;
}

.magnet__form form input{
	margin-bottom:20px;
	padding-left:0;
	padding-right:0;
	background-color:transparent;
	border:none;
	border-bottom:2px solid #fff;
	border-radius:0;
	transition:0.3s;
}

.magnet__form form input:focus{
	padding:16px;
	background-color:rgb(214, 79, 60);
	border-bottom-color:transparent;
	transition:0.3s;
}

.magnet__form form input:focus::-webkit-input-placeholder{
	color:rgb(214, 79, 60);
	text-shadow:none;
}

.magnet__form form input:focus:-ms-input-placeholder{
	color:rgb(214, 79, 60);
	text-shadow:none;
}

.magnet__form form input:focus::placeholder{
	color:rgb(214, 79, 60);
	text-shadow:none;
}

.magnet__form form .btn:hover,
.magnet__form form .btn:focus,
.magnet__form form .btn:active{
	color:#DA513D;
}

.page-customers{
}

.page-customers .customer-quote{
	padding-bottom:32px;
	border-bottom:4px solid #0099DC;
}

.page-footer{
	position:relative;
	width:100%;
	margin-bottom:72px;
	background-color:#293E50;
	box-shadow:0 0 10px 0 rgba(0, 0, 0, .30)
}

.page-footer .container{
	margin:0;
	padding:0;
}

.page-footer .column{
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	margin-bottom:0;
	padding:40px;
}

.page-footer .column:first-of-type{
	background-color:#fff;
}

.page-footer .column:last-of-type{
	color:#fff;
}

.page-footer .contact-form{
}

.page-footer .contact-form input:focus,
		.page-footer .contact-form textarea:focus{
	padding:16px;
	background-color:rgb(39, 59, 76);
	border-bottom-color:transparent;
}

.page-footer .contact-form input:focus::-webkit-input-placeholder,
		.page-footer .contact-form textarea:focus::-webkit-input-placeholder{
	color:rgb(39, 59, 76);
	text-shadow:none;
}

.page-footer .contact-form input:focus:-ms-input-placeholder,
		.page-footer .contact-form textarea:focus:-ms-input-placeholder{
	color:rgb(39, 59, 76);
	text-shadow:none;
}

.page-footer .contact-form input:focus::placeholder,
		.page-footer .contact-form textarea:focus::placeholder{
	color:rgb(39, 59, 76);
	text-shadow:none;
}

.user{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-flex:0;
      -ms-flex:0 0 25%;
          flex:0 0 25%;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  margin-bottom:16px
}

.user img{
	margin-right:16px;
}

.user p{
	margin-bottom:0;
}

.user--vert{
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	-webkit-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
}

.user--vert img{
	margin-right:0;
}

.user--vert .details{
	text-align:center;
}

.user--small{
}

.user--small img{
	width:50px;
}

.team-list{
	margin-bottom:100px
}

.team-list__member{
	padding-top:40px;
}

.team-list__member:nth-of-type(even){
	background-color:#fff;
}

.team-list__member{
	overflow:hidden
}

.team-list__member:last-of-type{
	margin-bottom:200px;
}

.team-list__member .column{
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	-ms-flex-line-pack:center;
	    align-content:center;
	-webkit-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center;
	margin-bottom:0;
}

.team-list__member h2{
	font-size:40px;
	font-weight:800;
	line-height:1;
	text-transform:uppercase;
}

.team-list__member .headline-small{
	color:#0099DC;
}

.team-list__member .btn{
	margin:0;
	margin-bottom:40px;
}

.team-list__member{
}

.team-list__member .column{
	margin-right:0;
}

.team-list__member .container .column:first-of-type{
	-webkit-box-ordinal-group:2;
	    -ms-flex-order:1;
	        order:1;
	padding-top:24px;
	border-top:10px solid #0099DC;
}

.team-list__member .container .column:last-of-type{
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:horizontal;
	-webkit-box-direction:normal;
	    -ms-flex-direction:row;
	        flex-direction:row;
	-webkit-box-align:end;
	    -ms-flex-align:end;
	        align-items:flex-end;
}

.team-list__member:nth-of-type(odd) .team{
	mix-blend-mode:multiply;
}

.category-nav__list{
}

.category-nav__list li{
	display:-webkit-inline-box;
	display:-ms-inline-flexbox;
	display:inline-flex;
	font-size:18px;
	font-weight:800;
	text-transform:uppercase;
}

.category-nav__list li a{
	margin:10px;
	color:#fff;
}

.category-nav__list li a:hover{
	color:#293E50;
	text-shadow:none;
}

.category-nav__list li:not(:first-of-type){
	margin-left:16px;
}

.category-list{
	font-size: 20px;
}

.article-header{
	width:100%;
	margin-bottom:48px;
	font-weight:700;
	font-size:36px;
	color:#0099DC;
	text-align:center;
}

.article-list{
	margin-top:24px;
	margin-bottom:100px
}

.article-list .column{
	max-width:366px;
	margin-bottom:40px;
	/*! THIS IS A CHANGE THAT ISN'T IN THE FILE */
	background-color:#fff;
	box-shadow:0 0 10px 0 rgba(0, 0, 0, .20);
}


.article-list .column__content h3{
	line-height: 36px;
}

.article-list .column a{
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	    -ms-flex-direction:column;
	        flex-direction:column;
	color:#DA513D;
}

.article-list .column a .featured-img{
	min-height:230px;
}

.article-list .column a .column__content{
	padding:20px;
	background-color:#fff;
	transition:0.2s;
}

.article-list .column a .column__content p{
	color:#555;
	transition:0.2s;
}

.article-list .column a .column__content time{
	float:right;
}

.article-list .column a:hover{
}

.article-list .column a:hover .column__content{
	color:#fff;
	background-color:#293E50;
	transition:0.1s;
}

.article-list .column a:hover .column__content p{
	color:#fff;
	transition:0.1s;
}

.article{
	position:relative;
	margin-top:-100px;
	margin-bottom:100px;
}

.article__content{
	background-color:#fff
}

.article__content--first{
	margin-top:-50px;	
}

.article__content:first-of-type{
	padding-top:100px;
}

.article__content:last-of-type{
	padding-bottom:100px;
}

.article__header{
	background-color:#fff;
}

.share{
	position:absolute;
	top:20px; right:30px;
	display:block;
}

.article__author{
	position:relative;
	top:-50px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	    -ms-flex-align:center;
	        align-items:center;
	-webkit-box-pack:center;
	    -ms-flex-pack:center;
	        justify-content:center
}

.article__author p,
	.article__author time{
	display:none;
}

.mugshot{
	width:142px;
	height:142px;
	margin-left:16px;
	margin-right:16px;
	text-align: center;
	background-color:#fff;
	border:10px solid #FFFFFF;
	border-radius:50%
}

.mugshot img{
	border-radius:50%;
}

.ci-image{
	width:100%;
	margin:0 auto;
}

.author{
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	margin-bottom:48px;
	padding:0;
	background-color:#293E50;
	box-shadow:0 0 10px 0 rgba(0, 0, 0, .30)
}

.author .column{
	margin-bottom:0;
}

.author .column:first-of-type{
	padding:64px 32px;
	text-align:center;
	background-color:#fff;
}

.author .column:last-of-type{
	padding:56px;
	color:#fff;
}

.author .column:last-of-type .column__content{
	max-width:480px;
	margin:0 auto;
}

.author h2{
	margin-bottom:20px;
	font-size:48px;
	font-weight:800;
	color:#0099DC;
	line-height:38px;
	letter-spacing:0.82px;
	text-transform:uppercase;
	word-spacing:500px;
}

.author hr{
	background-color:#555;
}

.author .btn{
	margin:0;
	margin-left:auto;
}

.author .btn:hover,
.author .btn:focus,
.author .btn:active{
	color:#293E50;
}

.author form{
	margin-bottom:0;
}

.author form input{
	margin-bottom:20px;
}

.author{
	display:none;
}

.related{
}

.related .headline-small{
	padding:20px 0 80px;
	color:#DA513D;
	text-align:center;
}

.contact-form{
	padding-top:32px
}

.contact-form input,
	.contact-form textarea{
	margin-bottom:20px;
}

.contact-form textarea{
	height:200px;
}

.contact-form input:focus,
	.contact-form textarea:focus{
	padding:16px;
	background-color:rgb(0, 150, 216);
	border-bottom-color:transparent;
}

.contact-form input:focus::-webkit-input-placeholder,
	.contact-form textarea:focus::-webkit-input-placeholder{
	color:rgb(0, 150, 216);
	text-shadow:none;
}

.contact-form input:focus:-ms-input-placeholder,
	.contact-form textarea:focus:-ms-input-placeholder{
	color:rgb(0, 150, 216);
	text-shadow:none;
}

.contact-form input:focus::placeholder,
	.contact-form textarea:focus::placeholder{
	color:rgb(0, 150, 216);
	text-shadow:none;
}

.contact-form .btn{
	float:right;
}

.contact-form .form-list{
	float:left;
	max-width:50%;
	margin-bottom:24px;
	padding-top:20px;
}

.contact-form .form-list li{
	width:20px;
	height:20px;
	margin-right:20px;
}

.contact-form .form-list li img{
	height:100%;
}

.contact{
	margin-bottom:80px
}

.contact .card--solo{
	z-index:20;
	margin-top:-50px;
}

.map-container{
	display:none;
}

@media only screen and (min-width: 420px){

	.hook h2{
		font-size:45px;
	}
}

@media only screen and (min-width: 480px){

	.card h2{
		word-spacing:normal;
	}
}

@media only screen and (min-width: 520px){

	.page-header h2{
		font-size:42px;
	}

	.page-list__item{
}

	.page-list__item .column{
		padding-left:40px;
		padding-right:40px;
	}

	.page-list__item .column--img{
		text-align:center;
	}

	.team-list__member:last-of-type{
		margin-bottom:300px;
	}
}

@media only screen and (min-width: 560px){

	.hero{
}

	.hero h1{
		font-size:54px;
		line-height:57px;
	}
}

@media only screen and (min-width: 600px){

	blockquote p{
		font-size:32px;
	}
}

@media only screen and (min-width: 640px){

	.team-list__member:last-of-type{
		margin-bottom:400px;
	}

	.team-list__member:last-of-type{
		margin-bottom:500px;
	}
}

@media only screen and (min-width: 680px){

	.panel-wrap{
}

	.panel-wrap .panel{
		width:50%;
		-webkit-box-ordinal-group:1;
		-ms-flex-order:0;
		order:0;
	}

	.panel-wrap:nth-of-type(3),
	.panel-wrap:nth-of-type(4){
		width:50%;
		-webkit-box-flex:1;
		-ms-flex:1 0 auto;
		flex:1 0 auto;
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-ms-flex-direction:column;
		flex-direction:column;
	}
}

@media only screen and (min-width: 720px){

	.hero{
}

	.hero h1{
		font-size:67px;
		line-height:70px;
	}
}

@media only screen and (min-width: 758px){

	.footer__copyright{
}

	.footer__copyright nav{
		display:block;
	}

	.footer__copyright .secondary-nav__list li{
		display:block;
		margin-bottom:8px;
		font-size:14px;
		letter-spacing:1.5px;
	}

	.footer__copyright .secondary-nav__list li a{
		display:block;
	}
}

@media only screen and (min-width: 768px){

	.container{
}

	.container--flex{
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-ms-flex-direction:row;
		flex-direction:row;
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
	}

	.container--flex .column:not(.column--img){
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
	}

	.container .column:last-of-type{
		margin-right:0;
	}

	.container .column--half{
		width:calc(99.9% * 1/2 - 10px);
	}

	.container .column--half:nth-child(2n){
		margin-right:0;
	}

	.container .column--third{
		width:calc(99.9% * 1/3 - 13.333333333333332px);
	}

	.container .column--third:nth-child(3n){
		margin-right:0;
	}

	.container .column--twothird{
		width:calc(99.9% * 2/3 - 6.666666666666666px);
	}

	.container .column--twothird:nth-child(3n){
		margin-right:0;
	}

	.container .column--quarter{
		width:calc(99.9% * 1/4 - 15px);
	}

	.container .column--quarter:nth-child(4n){
		margin-right:0;
	}

	.container--flex .column__content{
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-flex:1;
		-ms-flex:1;
		flex:1;
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-ms-flex-direction:column;
		flex-direction:column;
	}

	.container--flex .column__content p:not(.headline-small){
		-webkit-box-flex:1;
		-ms-flex:1;
		flex:1;
	}

	.primary-nav__list{
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-align:center;
		-ms-flex-align:center;
		align-items:center;
	}

	.primary-nav__list li{
		display:-webkit-inline-box;
		display:-ms-inline-flexbox;
		display:inline-flex;
	}

	.primary-nav__list li:not(:last-of-type){
		transition:0.3s;
	}

	.primary-nav__list li:not(:last-of-type) a{
		position:relative;
		display:block;
	}

	.primary-nav__list li:not(:last-of-type) a:before{
		content:"";
		position:absolute;
		width:100%;
		height:2px;
		bottom:-4px;
		left:0;
		background-color:#0099DC;
		visibility:hidden;
		-webkit-transform:scaleX(0);
		transform:scaleX(0);
		transition:all 0.3s ease-in-out 0s;
	}

	.primary-nav__list li:not(:last-of-type) a:hover{
}

	.primary-nav__list li:not(:last-of-type) a:hover:before{
		visibility:visible;
		-webkit-transform:scaleX(1);
		transform:scaleX(1);
	}

	.primary-nav__list li:first-of-type{
		margin-left:auto;
	}

	.primary-nav__list li:not(:first-of-type){
		padding-left:32px;
	}

	.primary-nav__list .btn{
		padding:8px 16px;
		text-shadow:0 1px 1px rgba(0, 0, 0, .20);
		border:none;
		border-radius:4px;
	}

	.primary-nav__list .btn--red{
		background-color:#DA513D;
	}

	.primary-nav__list .btn--red:hover,
	.primary-nav__list .btn--red:focus,
	.primary-nav__list .btn--red:active{
		background-color:rgb(196, 73, 55);
	}

	.primary-nav__toggle{
		display:none;
	}

	.mobile-nav{
		display:none;
	}

	.page-header form{
		float:left;
		margin-bottom:0;
	}

	.page-header--sub{
		padding-bottom:200px;
	}

	.page-header__content{
		text-align:left;
	}

	.page-header__content p{
		width:100%;
	}

	.subscribe{
}

	.subscribe .container{
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-align:center;
		-ms-flex-align:center;
		align-items:center;
		-webkit-box-pack:justify;
		-ms-flex-pack:justify;
		justify-content:space-between;
	}

	.subscribe h2{
		margin-bottom:0;
		margin-right:64px;
	}

	.subscribe form{
		position:relative;
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-pack:justify;
		-ms-flex-pack:justify;
		justify-content:space-between;
		-webkit-box-align:center;
		-ms-flex-align:center;
		align-items:center;
		-webkit-box-flex:1;
		-ms-flex:1 0 auto;
		flex:1 0 auto;
	}

	.subscribe form input{
		-webkit-box-flex:1;
		-ms-flex:1;
		flex:1;
		margin-bottom:0;
	}

	.subscribe form .btn{
		-webkit-box-flex:1;
		-ms-flex:1;
		flex:1;
		margin-left:64px;
	}

	.about{
		background-repeat:no-repeat;
		background-size:cover;
		background-position:center;
	}

	.about--bluefish{
		/*background-image:url(../images/team-blue-fish.jpg);*/
	}

	.about--bluefish .about__content{
		-ms-flex-item-align:end;
		align-self:flex-end;
	}

	.about--madness{
		background-image:url(../images/Marketing_Madness-v2-2390.jpg);
	}

	.about--madness .about__content{
		-ms-flex-item-align:start;
		align-self:flex-start;
	}

	.about__content{
		max-width:340px;
	}

	.page-list__item{
		padding-top:100px;
		padding-bottom:100px;
	}

	@media only screen and (min-width: 800px){

		.page-list__item h2{
			font-size:40px;
		}
	}
	@media only screen and (min-width: 1025px){
		.page-header{
			position:relative;
			z-index:10;
			left:10%;
			width:90%;
			margin-top:-50px;
		}

		.page-header h2{
			float:left;
			width:450px;
			margin-right:50px;
			text-align:right;
		}

		.page-header p{
			float:left;
			text-align:left;
		}

		.page-header p.text-large{
			font-size:30px;
		}

		.page-header{
			margin-top:-100px;
		}

		.page-header p{
			max-width:600px;
		}
	}

	.page-list__item .column{
		padding-left:40px;
		padding-right:40px;
	}

	.page-list__item:nth-of-type(odd){
}

	.page-list__item:nth-of-type(odd) .column--half:first-of-type{
		float:right;
		left:50%;
		margin-right:0;
	}

	.page-list__item:nth-of-type(odd) .column--half:last-of-type{
		left:-50%;
		margin-right:20px;
	}

	.page-block{
		padding-top:100px;
		padding-bottom:100px;
	}

	.page-block h2{
		font-size:30px;
	}

	.page-block h3{
		font-size:26px;
	}

	.column-box{
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-ms-flex-flow:row wrap;
		flex-flow:row wrap;
		width:100%;
		height:auto;
		-webkit-box-pack:justify;
		-ms-flex-pack:justify;
		justify-content:space-between;
	}

	.magnet__text,
	.column-block{
		-webkit-box-flex:1;
		-ms-flex:1;
		flex:1;
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
		-webkit-box-align:start;
		-ms-flex-align:start;
		align-items:flex-start;
		-ms-flex-line-pack:start;
		align-content:flex-start;
		max-width:40%;
		margin-bottom:0;
	}

	.magnet__form,
	.column-block--img{
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
		min-width:0;
		-webkit-box-flex:0;
		-ms-flex:0 auto;
		flex:0 auto;
		-webkit-box-pack:center;
		-ms-flex-pack:center;
		justify-content:center;
		-webkit-box-align:center;
		-ms-flex-align:center;
		align-items:center;
		-ms-flex:0 0 55%;
		flex:0 0 55%;
		max-width:55%;
		padding-right:5%;
	}

	.magnet__form{
		padding-left:5%;
		padding-right:0;
	}

	.magnet__form .btn{
		float:left;
	}

	.column-block--right{
		-webkit-box-ordinal-group:2;
		-ms-flex-order:1;
		order:1;
		padding-left:5%;
	}

	.page-img{
		height:400px;
	}

	.page-footer{
}

	.page-customers .column--third{
		width:calc(99.9% * 1/3 - 26.666666666666664px);
		margin-right:40px;
		margin-bottom:32px;
	}

	.team-list__member .container .column:first-of-type{
		-webkit-box-ordinal-group:1;
		-ms-flex-order:0;
		order:0;
		padding-top:0;
		border-top:none;
	}

	.team-list__member:nth-of-type(odd){
}

	.team-list__member:nth-of-type(odd) .column--half:first-of-type{
		float:right;
		left:50%;
		margin-right:0;
	}

	.team-list__member:nth-of-type(odd) .column--half:last-of-type{
		left:-50%;
		margin-right:20px;
	}

	.article-list .column{
		margin-bottom:20px;
	}

	.article__content{
		padding-left:50px;
		padding-right:50px;
	}

	.article__author{
}

	.article__author p,
		.article__author time{
		display:block;
		width:200px;
		padding-top:20px;
		font-weight:800;
	}

	.article__author p{
		width:200px;
		margin-bottom:0;
		text-align:right;
	}

	.author{
}

	.author .column:first-of-type{
		width:calc(35% - 20px);
	}

	.author .column:last-of-type{
		width:65%;
	}
}

@media only screen and (min-width: 800px){

	.footer__copyright{
}

	.footer__copyright .secondary-nav__list li{
		display:inline-block;
		margin-bottom:0;
	}

	.footer__copyright .secondary-nav__list li:not(:first-of-type){
		margin-left:12px;
	}

	.footer__copyright .secondary-nav__list li:not(:first-of-type):before{
		content:"|";
		padding-right:12px;
	}

	.footer__copyright .secondary-nav__list li a{
		display:inline-block;
	}

	.page-list__item h2{
		font-size:40px;
	}
}

@media only screen and (min-width: 820px){

	.hero{
		margin-top:50px;
		margin-bottom:200px;
	}

	.card{
		width:33.333%;
		height:450px;
		margin-bottom:0;
	}

	.card h2{
		word-spacing:500px;
	}

	.focus{
		height:300px;
	}

	.focus .container--flex{
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-ms-flex-direction:row;
		flex-direction:row;
	}

	.focus .card:first-of-type{
		left:10px;
	}

	.focus .card:last-of-type{
		right:10px;
	}

	.focus .card:not(:nth-of-type(2)){
		margin-top:-100px;
	}

	.focus .card:nth-of-type(2){
		z-index:25;
		margin-top:-150px;
	}

	.about__content{
		padding-top:100px;
		padding-bottom:100px;
	}

	.team-list{
		padding-top:100px;
	}

	.article__content{
		padding-left:100px;
		padding-right:100px;
	}

	.category-nav__list{
}

	.category-nav__list li{
		font-size:22px;
	}

	.category-nav__list li a{
		margin:10px 20px;
	}

	.map-container{
		display:block;
		position:relative;
		left:10%;
		width:90%;
		height:500px;
		margin-top:-600px;
		margin-bottom:60px;
		box-shadow:0 0 10px 0 rgba(0, 0, 0, .30);
	}

	#map{
		width:100%;
		height:100%;
	}
}

@media only screen and (min-width: 980px){

	.hero{
		margin-top:150px;
	}

	.page-header--contact{
		margin-bottom:50px;
		background:#0099DC url(../images/mobile-skyline.jpg) no-repeat;
		background-position-x:-20%;
	}

	.page-header__content{
		max-width:560px;
		margin-left:400px;
	}

	.latest{
		top:-100px;
	}

	.latest + .content{
		top:-100px;
	}

	.services{
		position:relative;
		z-index:10;
		-webkit-box-flex:69%;
		-ms-flex:69%;
		flex:69%;
		margin-bottom:0;
	}

	.panel-wrap:nth-of-type(4){
		-ms-flex-item-align:start;
		align-self:flex-start;
	}

	.blogroll{
		position:relative;
		z-index:10;
		-webkit-box-flex:31%;
		-ms-flex:31%;
		flex:31%;
		margin-top:-67px;
		-ms-flex-item-align:start;
		align-self:flex-start;
	}

	.card h2{
		font-size:42px;
		line-height:32px;
	}

	.hook h2{
		font-size:65px;
	}

	.footer__copyright{
		text-align:left;
	}

	.footer__copyright p,
		.footer__copyright nav{
		display:inline-block;
	}

	.footer__copyright p{
		margin-bottom:0;
	}

	.footer__copyright nav{
		float:right;
	}

	.focus--sub{
		height:auto;
	}

	.page-footer{
		left:10%;
		width:90%;
	}

	.page-footer .column{
		padding:72px;
	}

	.page-block{
}

	.page-block h2:last-of-type{
		margin-bottom:0;
	}

	.page-block h2{
		font-size:40px;
	}

	.page-block h3{
		font-size:34px;
	}

	.contact .card--solo{
		margin-top:-150px;
	}

	.map-container{
		margin-top:-400px;
	}
}

@media only screen and (min-width:980px){

	.about--madness{
		margin-top:-75px;
	}
}

@media only screen and (min-width: 1300px){

	.page-header{
		padding:100px 200px;
	}

	.page-header--extra{
		padding-bottom:200px;
	}

	.page-header--contact{
		padding-bottom:100px;
		background-position-x:0;
	}

	.latest,
	.latest + .content{
		top:-175px;
	}

	.about__content{
		padding-top:200px;
		padding-bottom:200px;
	}
}

.page-header{
	display: flex;
	flex-direction: column;
}

@media only screen and (min-width: 1025px){
	.page-header{
		flex-direction: row;
	}
}

@media only screen and (max-width: 767px){

	.primary-nav__list{
		display:block;
		position:fixed;
		bottom:-100%;
		left:0;
		z-index:50;
		width:100%;
		height:100%;
		visibility:hidden;
		opacity:0;
		overflow-y:auto;
		background-color:#0099DC;
		transition:all .5s ease;
	}

	.primary-nav__list.is-opened{
		bottom:0;
		visibility:visible;
		opacity:1;
	}

	.primary-nav__list li{
		display:block;
		width:80%;
	}

	.primary-nav__list li:first-of-type{
		margin-top:56px;
	}

	.primary-nav__list li a{
		display:block;
		padding:16px 30px;
	}

	.primary-nav__list li a:hover,
				.primary-nav__list li a:focus,
				.primary-nav__list li a:active{
		background-color:#293E50;
	}

	.primary-nav__list li a.active{
		background-color:#293E50;
	}

	.primary-nav__toggle{
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-align:center;
		-ms-flex-align:center;
		align-items:center;
		width:25px;
		height:25px;
		margin-left:auto;
		text-align:right;
	}

	.is-opened + .primary-nav__toggle{
		position:relative;
		z-index:101;
	}
}

.column--landing-text h2{
	margin-bottom:20px !important;
}

@media only screen and (max-width: 767px){
	.column--center{
		margin:0 auto !important;
	}
}

.hide-on-desk{
	display: block;
}

.hide-on-mobile{
	display: none;
}

.category-mobile{
	background:#0099dc;
}

.category-mobile__list > li{
	margin:0 20px;
	padding:10px 20px;
	font-size: 16px;
  font-weight: 700;
	text-transform: uppercase;
	border-bottom: 1px solid #0179ac;
}

.category-mobile__list > li > a{
	color:#fff;
}

.category-mobile__btn{
	display:flex;
	align-items: center;
	justify-content: space-between;
	width:100%;
	padding:0;
	font-size: 18px;
	font-weight: 800;
	text-transform: uppercase;
	color:#fff;
	background:transparent;
}

.show-category{
	margin-bottom: 10px;
	padding: 10px 20px;
}

.show-category > svg{
	height: 16px;
	width: 16px;
	transition: all .3s linear;
}

.rotate{
	transition: all .3s linear;
	transform: rotateX(180deg);
	
}
.show-category.active{
	background:#0082bb;
}

.sticky{
	position: fixed;
	top:0;
  bottom:50px;
  overflow-y:scroll;
	overflow-x:hidden;
	z-index: 120;
	width:100%;
}

@media only screen and (min-width: 767px){
	.hide-on-desk{
		display: none;
	}

	.hide-on-mobile{
		display: block;
	}
}

.no-scroll{
  height: 100%;
  overflow: hidden;
}

